<template>
  <div>
    <content-template
      :menuName="`${
        inputType == 'Edit' ? $t('general.edit') : $t('general.new')
      } ${$t('brrdFileNew.title')}`"
      :backButton="true"
      @backToPage="cancelForm()"
    >
      <template #form>
        <form>
          <Input
            v-model="edited_item.title"
            :label="$t('general.title')"
            class="s-mb-20"
            :error="validation.title"
          />

          <Textarea
            v-model="edited_item.description"
            :label="$t('general.content')"
            class="s-mb-20"
            :error="validation.description"
          />

          <div class="s-pb-30 s-position-relative">
            <p class="s-text-primary s-weight-600">
              {{ $t("general.uploadFile") }} (PDF)
            </p>
            <div v-if="inputType == 'Edit'"></div>
            <div class="s-pb-30 s-position-relative">
              <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
                <div>
                  <img
                    :src="require('@/assets/icons/primary/cloud_upload.png')"
                  />
                  <p>{{ $t("general.dragAndDrop") }}</p>
                  <p>{{ $t("general.or") }}</p>
                  <Button>{{ $t("general.uploadFile") }}</Button>
                </div>
              </div>
              <input
                v-if="!fileDoc.name"
                id="document"
                ref="document"
                class="s-hide"
                type="file"
                @change="importData('document')"
                accept="application/pdf"
              />
              <div class="s-position-relative s-fullWidth s-center-flex">
                <div
                  class="
                    s-center-flex
                    s-mb-16
                    s-bg-green-40
                    s-plr-10
                    s-radius-10
                    s-fullWidth
                  "
                  v-if="fileDoc.name"
                >
                  <p class="s-text-primary">{{ fileDoc.name }}</p>
                  <img
                    :src="require('@/assets/icons/close-primary.svg')"
                    class="s-ml-auto"
                    @click="fileDoc = {}"
                    style="cursor: pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button class="s-ml-auto" color="error" @click="cancelForm()">
              {{ $t("general.cancel") }}
            </Button>
            <Button
              class="s-ml-20"
              @click="onFinish()"
              :isLoading="isLoading"
              >{{ $t("general.submit") }}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import common from "@/mixins/common";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  mixins: [form, common],
  props: ["inputType"],
  data: () => ({
    edited_item: {
      title: "",
      description: "",
      image: {},
    },

    validation: {
      title: false,
      description: false,
    },

    fileDoc: {},
    value: [],
    brrd_id: "",
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      expertise_dropdown: "expertise/getExpertise",
      brrd_data: "contentfile/getContent",
    }),
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    title() {
      return this.edited_item.title == "" ? true : false;
    },
  },
  mounted() {
    this.initData();
    this.getEditedData();
  },
  methods: {
    ...mapActions({
      content: "contentfile/postContentData",
      setExpertise: "expertise/setExpertiseDropdownData",
      setContentSingleData: "contentfile/setContentSingleData",
      content_update: "contentfile/updateContentData",
    }),

    async initData() {
      this.setLoadingPage(true);
      await this.setExpertise();
      if (this.inputType == "Edit") {
        await this.setContentSingleData(this.$route.params.id);
      }
      this.brrd_id =
        this.inputType == "New"
          ? this.$route.params.id
          : this.brrd_data.brrd_id;
      this.setLoadingPage(false);
    },

    async getEditedData() {
      if (this.inputType == "Edit") {
        await this.setContentSingleData(this.$route.params.id);
        this.edited_item = Object.assign({}, this.brrd_data);
        this.edited_item.title = this.brrd_data.alt;
        this.fileDoc.name = this.brrd_data.filename;
      }
    },

    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        true,
        this.fileDoc.name,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("brrd_id", this.$route.params.id);
        formData.append("title", this.edited_item.title);
        formData.append("description", this.edited_item.description);
        formData.append("file", this.fileDoc);

        if (this.inputType == "New") {
          await this.content(formData);
        } else {
          formData.append("id", this.brrd_data.id);
          await this.content_update(formData);
        }

        if (this.$store.getters["contentfile/getStatus"] == 1) {
          this.$router.push(
            this.$translate({
              name: "BRRD File",
              params: { id: this.brrd_id },
            })
          );
        } else {
          this.showSnackbar({
            type: "error",
            text: "general.addEditFailed",
          });
        }
        this.isLoading = false;
      }
    },

    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },
    cancelForm() {
      if (this.inputType == "Edit")
        this.$router.push(
          this.$translate({
            name: "BRRD File",
            params: { id: this.brrd_data.brrd_id },
          })
        );
      else
        this.$router.push(
          this.$translate({
            name: "BRRD File",
            params: { id: this.brrd_id },
          })
        );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
